import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';

import LocationChangeListener from './locationChangeListener';

import userRoles from '../globals/userRoles';

import AppLayout from './AppLayout/AppLayout';
import IndexRedirectHandler from '../routes/IndexRedirectHandler';
import NotFoundView from '../views/errors/NotFoundView';

// List Pages
import MySchoolPage from '../routes/MySchool/MySchool';
import ClassroomPage from '../routes/ClassroomPage/ClassroomPage';
import MyProfilePage from '../routes/MyProfile/MyProfile';

import UserProfileInit from '../routes/UserProfile/UserProfileInitialising';

import CreateOrganisation from '../routes/CreateOrganisation/CreateOrganisation';

// import isRegion from '../utils/isRegion';

// Auth pages
import RegisterPage from '../routes/Register/RegisterPage';
import RequestPasswordReset from '../routes/RequestPasswordReset/RequestPasswordReset';
import ResetPassword from '../routes/ResetPassword/ResetPassword';
import InvitationUpdatePage from '../routes/Register/Invites/InvitationUpdatePage';

// Redirect to Auth0
import LoginPage from '../routes/LoginPage/LoginPage';

// Support pages
import SupportHomePage from '../routes/Support/SupportHomePage';
import SupportArticlePage from '../routes/Support/SupportArticle/SupportArticlePage';

// ORB pages
import ORBLayout from './ORBLayout/ORBLayout';
import OrbClassReportPage from '../routes/development/ORB/OrbClassReportPage';
import OrbTeacherResourcesPage from '../pages/OrbTeacherResourcesPage';
import OrbOfflineAppPage from '../pages/OrbOfflineAppPage';
import OrbTeacherContactPreferencesPage from '../pages/OrbTeacherContactPreferencesPage/OrbTeacherContactPreferencesPage';

// Blank page
import BlankPage from '../routes/BlankPage/BlankPage';

// OALD Redeem-code pages
import RedeemCodePage from '../routes/RedeemCode/RedeemCodePage';
import RedeemCodeSuccessPage from '../routes/RedeemCode/RedeemCodeSuccessPage';

import StudentAnswerViewPage from '../routes/Gradebook/StudentAnswerViewPage';

import protectedRoute from './protectedRoute';
import withAuthorization from '../authorization/withAuthorization';
import { limitToOrgRoles } from '../redux/selectors/authorization/organization';

import { LegacyRegisterOrgPage } from '../pages';

import DownloadSigninCardProcess from '../components/DownloadSigninCardProcess/DownloadSigninCardProcess';
// show invitations
import ConnectedBannerInvitation from '../components/BannerInvitation/ConnectedBannerInvitation';

// Decline Org Invitation from Email
import DeclineOrgInvitation from '../routes/DeclineOrgInvitation/DeclineOrgInvitation';
import OrbRegionDemoPage from '../routes/development/ORB/OrbRegionDemoPage';
import OrbRegionDemoRoot from '../routes/development/ORB/OrbRegionDemoRoot';
// start up pages
import StartTips from '../routes/ORBFirstTimeVisitPages/StartTips';
import Test from '../pages/dashboard/Test/Test';

function RouteManager() {
  return (
    <BrowserRouter>
      <LocationChangeListener>
        <Switch>
          {/* Blank page for loading core assets only, used for OUP.VIEW.embed prior to .gotoPage(...) */}
          <Route path="blank">
            <BlankPage />
          </Route>

          {/* redirect to Auth0 */}
          <Route path="/login">
            <LoginPage />
          </Route>

          {/* ORB Demo Pages */}
          <Route path="/orb-region">
            <OrbRegionDemoRoot>
              <OrbRegionDemoPage />
            </OrbRegionDemoRoot>
          </Route>

          <Route exact path="/">
            <AppLayout>
              <IndexRedirectHandler />
            </AppLayout>
          </Route>

          <Route path="/teacherHome/reporting/:orgId?/classroom/:classroomId/:tabName/:objectId">
            <AppLayout>
              <ORBLayout>
                <OrbClassReportPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          <Route path="/teacherHome/reporting/:orgId?/:tabName?/:objectId?">
            <AppLayout>
              <ORBLayout>
                <OrbClassReportPage />
              </ORBLayout>
            </AppLayout>
          </Route>
          <Route path="/getLogs">
            <AppLayout>
              <ORBLayout>
                <Test />
              </ORBLayout>
            </AppLayout>
          </Route>
          <Route path="/teacherHome/library">
            <AppLayout>
              <ORBLayout>
                <OrbTeacherResourcesPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          <Route path="/teacherHome/offlineApp">
            <AppLayout>
              <ORBLayout>
                <OrbOfflineAppPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          <Route path="/teacherHome/contactPreferences">
            <AppLayout>
              <ORBLayout>
                <OrbTeacherContactPreferencesPage />
              </ORBLayout>
            </AppLayout>
          </Route>

          {/* The token param can be one of:
            - Blank string for Full Registration.
            - 'finish' for completing a Partial Registration of existing or legacy user.
            - Any userToken string for completing a Partial Registration via an email link with a token. */}
          <Route path="/register/:token?">
            <AppLayout>{protectedRoute(RegisterPage, false, Object.keys(userRoles), '/myProfile')}</AppLayout>
          </Route>

          <Route path="/invitation">
            <AppLayout>
              <InvitationUpdatePage />
            </AppLayout>
          </Route>

          <Route path="/reset-password/:token">
            <AppLayout>{protectedRoute(ResetPassword, false, Object.keys(userRoles), '/myProfile')}</AppLayout>
          </Route>

          <Route path="/reset-password">
            <AppLayout>{protectedRoute(RequestPasswordReset, false, Object.keys(userRoles), '/myProfile')}</AppLayout>
          </Route>

          {/* List Pages */}
          {/* Org page url for most people will be /mySchool but for admins it will be /org/xxx */}
          <Route path="/mySchool/:tabName?/:panelName?">
            <AppLayout>
              {protectedRoute(
                MySchoolPage,
                false,
                [userRoles.USER, userRoles.LEARNER],
                '/myProfile',
                false,
                false,
                'MY_ORG'
              )}
            </AppLayout>
          </Route>

          <Route path="/org/:orgId/user/:userId/:tabName?/:panelName?">
            <AppLayout>
              {protectedRoute(UserProfileInit, false, [userRoles.USER, userRoles.LEARNER], '/myProfile')}
            </AppLayout>
          </Route>

          <Route path="/org/:orgId/complete-registration">
            <AppLayout>
              <LegacyRegisterOrgPage />
            </AppLayout>
          </Route>

          {/* Download Signin Card Process */}
          <Route path="/org/:orgId/batchId/:batchId/records/:records">
            <AppLayout>
              <DownloadSigninCardProcess />
            </AppLayout>
          </Route>

          <Route path="/org/:orgId/class/:classroomId/:tabName?/:panelName?/:itemId?/:learnerId?">
            <AppLayout>
              {protectedRoute(ClassroomPage, false, [userRoles.USER, userRoles.LEARNER], '/myProfile')}
            </AppLayout>
          </Route>

          <Route path="/org/:orgId/:tabName?/:panelName?">
            <AppLayout>{withAuthorization(MySchoolPage, limitToOrgRoles(), '/')}</AppLayout>
          </Route>

          <Route path="/myProfile/:tabName?/:panelName?/:itemId?">
            <AppLayout>
              <MyProfilePage />
            </AppLayout>
          </Route>

          {/* Create Org Page */}
          <Route path="/createOrganisation">
            <AppLayout>
              <CreateOrganisation />
            </AppLayout>
          </Route>

          {/* Support pages */}
          <Route path="/support/articles/:pageName?">
            <AppLayout>
              <SupportArticlePage />
            </AppLayout>
          </Route>

          <Route path="/support/:tabName?">
            <AppLayout>
              <SupportHomePage />
            </AppLayout>
          </Route>

          <Route path="/redeem">
            <AppLayout>
              {protectedRoute(RedeemCodePage, false, Object.keys(userRoles), '/myProfile/learningMaterial/addCode')}
            </AppLayout>
          </Route>

          <Route path="/redeem/code">
            <AppLayout>
              <RedeemCodeSuccessPage />
            </AppLayout>
          </Route>

          {/* Decline Org Invitation */}
          <Route path="/decline-invite/:token">
            <AppLayout>
              <DeclineOrgInvitation />
            </AppLayout>
          </Route>

          {/* Show Invitations */}
          <Route path="/showInvitations">
            <AppLayout>
              <ConnectedBannerInvitation />
            </AppLayout>
          </Route>

          <Route path="/studentAnswerView/orgId/:orgId/class/:classroomId/:itemId/:learnerId/:bid/:uId/:activityId">
            <AppLayout>
              <StudentAnswerViewPage />
            </AppLayout>
          </Route>

          <Route path="/orb-start-tips/:step?">
            <AppLayout>
              <StartTips />
            </AppLayout>
          </Route>

          {/* 404 */}
          <Route path="*">
            <AppLayout>
              <NotFoundView />
            </AppLayout>
          </Route>
        </Switch>
      </LocationChangeListener>
    </BrowserRouter>
  );
}

export default RouteManager;
