import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

function Test() {
  return (
    <div>
      <h1>Hello World</h1>
    </div>
  );
}

export default compose(withRouter)(Test);
